import React from "react"
import { graphql } from "gatsby"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import Layout from "../components/layout"
import DummyHero from "../components/dummy-hero"
import Meta from "../components/meta"
import logo from "../images/favicon.png"
import CustomLink from "../components/custom-link"
import "./page.css"

function Page({ data }) {
  const title = data.strapiPage.title
  const slug = data.strapiPage.slug

  const seo = {
    title: data.strapiPage.seo.title,
    description: data.strapiPage.seo.description,
  }

  const body = data.strapiPage.body.data.body

  let meta = {
    title: `${title}`,
    description: `${seo.description}`,
    url: `https://www.digitallaboratory.net/${slug}/`,
    image: logo,
  }

  return (
    <Layout>
      <DummyHero title={title} />
      <div className="pageWrapper">
        <ReactMarkdown components={{ a: CustomLink }}>{body}</ReactMarkdown>
      </div>
    </Layout>
  )
}

export const Head = ({data}) => {
  // console.log("blogs: ", blogs);
  const title = data.strapiPage.title
  const slug = data.strapiPage.slug

  const seo = {
    title: data.strapiPage.seo.title,
    description: data.strapiPage.seo.description,
  }

  const body = data.strapiPage.body.data.body

  let meta = {
    title: `${title}`,
    description: `${seo.description}`,
    url: `https://www.digitallaboratory.net/${slug}/`,
    image: logo,
  }

  return <Meta meta={meta} />
}

export const query = graphql`
  query ($id: String!) {
    strapiPage(id: { eq: $id }) {
      title
      slug
      body {
        data {
          body
        }
      }
      seo {
        title
        description
      }
    }
  }
`

export default Page
