import React from 'react'
import { Link } from 'gatsby';

function index({ href, ...rest }) {

    const isInternalLink = href && href.startsWith('/')
    const isAnchorLink = href && href.startsWith('#')
  
    if (isInternalLink) {
      return (
        <Link to={href} {...rest}>
        </Link>
      )
    }
  
    if (isAnchorLink) {
      return <a href={href} {...rest} />
    }
  
    return <a target="_blank" rel="noopener noreferrer" href={href} {...rest} />

    
}

export default index;